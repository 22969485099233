<div>
  <app-roam-accordion-two-template
    [title]="'Work Order Details'"
    [expand]="true"
    [isEdit]="true"
    (onEdit)="navigateToPage(1)">
    <ng-container template>
      <div class="set-grid set-grid-3">
        <div>
          <div class="fw-600">Work Order Title</div>
          <div>Fix Ceiling</div>
        </div>
        <div>
          <div class="fw-600">Work Order Category</div>
          <div>Repair</div>
        </div>
        <div>
          <div class="fw-600">Work Order Priority</div>
          <div>Low</div>
        </div>
        <div>
          <div class="fw-600">Due Date</div>
          <div>06/30/2024</div>
        </div>
        <div class="fw-600 set-grid gap-10">
          <span
            class="d-flex"
            [inlineSVG]="'./assets/svg/check-green-round.svg'"></span>
          I am doing this work order
        </div>
      </div>
    </ng-container>
  </app-roam-accordion-two-template>

  <app-roam-accordion-two-template
    [title]="'Customer Details'"
    [expand]="false"
    [isEdit]="true"
    (onEdit)="navigateToPage(0)">
    <ng-container template>
      <div class="set-grid set-grid-3">
        <div>
          <div class="fw-600">Customer Summary</div>
          <div>{{ "Mark Gold (mark@mail.com)" }}</div>
          <div>Home: 999-999-1212</div>
          <div>Work: -</div>
          <div>Mobile: -</div>
        </div>
        <div>
          <div class="fw-600">Service Contact Info</div>
          <div>Mark Gold</div>
          <div>1520 Anslee Lakes</div>
        </div>
        <div>
          <div class="fw-600">Billing Contact Info</div>
          <div>Mark Gold</div>
          <div>1520 Anslee Lakes</div>
        </div>
      </div>
    </ng-container>
  </app-roam-accordion-two-template>

  <app-roam-accordion-two-template
    [title]="'Schedule & Assign'"
    [expand]="false"
    [isEdit]="true"
    (onEdit)="navigateToPage(2)">
    <ng-container template>
      <div class="set-grid set-grid-3">
        <div>
          <div class="fw-600">Due Date</div>
          <div>06/30/2024</div>
        </div>
        <div>
          <div class="fw-600">Schedule Start Time</div>
          <div>06/22/2024 08:00 AM</div>
        </div>
        <div>
          <div class="fw-600">Schedule End Time</div>
          <div>06/22/2024 08:00 AM</div>
        </div>
        <div>
          <div class="fw-600">Assigned Vendor Employee</div>
          <div>Lucas Gray</div>
        </div>
      </div>
    </ng-container>
  </app-roam-accordion-two-template>

  <app-roam-accordion-two-template
    [title]="'Others'"
    [expand]="false"
    [isEdit]="true"
    (onEdit)="navigateToPage(1)">
    <ng-container template>
      <div class="set-grid set-grid-3">
        <div>
          <div class="fw-600">Type of Service</div>
          <div>Others</div>
        </div>
        <div>
          <div class="fw-600">Access Code</div>
          <div>ABC123</div>
        </div>
        <div>
          <div class="fw-600">Pet on Site</div>
          <div>No</div>
        </div>
        <div>
          <div class="fw-600">Customer Preference</div>
          <div>Lorem ipsum</div>
        </div>
        <div>
          <div class="fw-600">Cost Estimate</div>
          <div>$100.00</div>
        </div>
        <div>
          <div class="fw-600">Actual Cost</div>
          <div>-</div>
        </div>
        <div>
          <div class="fw-600">File Input</div>
          <div>Open Link</div>
        </div>
      </div>
    </ng-container>
  </app-roam-accordion-two-template>

  <app-roam-accordion-two-template
    [title]="'Line items'"
    [expand]="false"
    [isEdit]="true"
    (onEdit)="navigateToPage(1)">
    <ng-container template>
      <div>
        <h6 class="title mt-8 tc-dust">SERVICES</h6>
        <div class="justify-between">
          <div>
            <div class="tc-black font-14px fw-500">Fix Hvac</div>
            <div class="tc-dust font-12px">{{ "Qty 2 @ $100,00/Each" }}</div>
          </div>
          <div>$0.00</div>
        </div>
      </div>
      <div>
        <h6 class="title mt-8 tc-dust">MATERIAL</h6>
        <div class="justify-between">
          <div>
            <div class="tc-black font-14px fw-500">Material name</div>
            <div class="tc-dust font-12px">{{ "Qty 2 @ $100,00/Each" }}</div>
          </div>
          <div>$0.00</div>
        </div>
      </div>
      <hr />
      <div>
        <div class="justify-between">
          <div class="font-14px tc-black fw-500">Subtotal</div>
          <div class="font-12px">$0.00</div>
        </div>
        <div class="justify-between">
          <div class="font-14px tc-dust">Discount</div>
          <div class="tc-dust font-12px">$0.00</div>
        </div>
        <div class="justify-between">
          <div class="font-14px tc-dust">Tax</div>
          <div class="tc-dust font-12px">$0.00</div>
        </div>
      </div>
    </ng-container>
  </app-roam-accordion-two-template>

  <app-roam-accordion-two-template
    [title]="'Service Task'"
    [expand]="false"
    [isEdit]="true"
    (onEdit)="navigateToPage(1)">
    <ng-container template>
      <app-table
        [httpPagination]="true"
        [isLoading]="isLoading"
        [tableConfig]="tableConfig"
        [emptyState]="emptyText"
        (mouseOverChange)="onMouseOverChange($event)"
        (selectData)="navigateToDetail($event.id)"
        (pageChange)="onPageChange($event)">
        <ng-template #customTemplate let-key="key" let-data="data">
        </ng-template>
      </app-table>
    </ng-container>
  </app-roam-accordion-two-template>

  <app-roam-accordion-two-template
    [title]="'Work Order Attachment'"
    [expand]="false"
    [isEdit]="true"
    (onEdit)="navigateToPage(1)">
    <ng-container template>
      <file-slider [files]="SAMPLE_FILES" />
    </ng-container>
  </app-roam-accordion-two-template>
</div>
