import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  effect,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { iconLib } from "@app/core/const/roam-icon";
import { Subscription } from "rxjs";
import { NewWorkOrderPages } from "../../const/maintenance-new-work-order-steps.const";
import { IPageStepper } from "@app/shared/interfaces/page-stepper.interface";
import { ConfirmationDialogComponent } from "@app/shared/components/roam-dialog/confirmation-dialog/confirmation-dialog.component";
import { dialogConfig } from "@app/core/const/dialog.const";
import { SaveDataConfirmationConfig } from "@app/shared/components/roam-dialog/shared/const/confirmation-dialog.const";
import { NewWorkOrderService } from "./new-work-order.service";

@Component({
  selector: "app-maintenance-dialog-new-work-order",
  templateUrl: "./maintenance-dialog-new-work-order.component.html",
  styleUrls: ["./maintenance-dialog-new-work-order.component.scss"],
})
export class MaintenanceDialogNewWorkOrderComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  public workOrderId!: string;
  public currentIndex: number = 0;
  public newWorkOrderPages: IPageStepper[] = NewWorkOrderPages;
  public startPage: boolean = true;
  public isSubmit: boolean = false;
  public icon = iconLib;
  public isLoading!: boolean;
  private subscription: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<MaintenanceDialogNewWorkOrderComponent>,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private newWorkOrderService: NewWorkOrderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.openAddLeaseForm();
      this.currentIndex = data.step;
      this.newWorkOrderPages[this.currentIndex].active = true;

      for (let index = 0; index < this.currentIndex; index++) {
        this.newWorkOrderPages[index].active = true;
      }
    }
  }

  ngOnInit(): void {}

  ngAfterContentChecked(): void {}

  ngOnDestroy(): void {
    this.newWorkOrderPages.forEach(step => {
      step.active = step.step === 1;
    });
    this.subscription.forEach(each => each.unsubscribe());
  }

  get currentPage(): IPageStepper {
    return this.newWorkOrderPages[this.currentIndex];
  }

  get buttonNextText(): string {
    const buttonName: any = {
      "preview-and-save": "Create Work Order",
    };

    return buttonName[this.currentPage.id as string] || "Next";
  }

  public openAddLeaseForm(): void {
    this.startPage = false;
  }

  protected onCurrentStepChange = effect(() => {
    const page = this.newWorkOrderService.currentStep();
    this.currentIndex = page;

    this.newWorkOrderPages.map(work => {
      work.active = work.step < page + 1;
    });

    this.newWorkOrderPages[this.currentIndex].active = true;
  });

  public nextPage(): void {
    if (this.currentIndex < this.newWorkOrderPages.length - 1) {
      this.currentIndex++;
      this.newWorkOrderPages[this.currentIndex].active = true;
    } else {
      this.dialogRef.close(true);
    }
  }

  public prevPage(): void {
    if (this.currentIndex > 0) {
      this.newWorkOrderPages[this.currentIndex].active = false;
      this.currentIndex--;
    }
  }

  public confirmCloseDialog(): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        ...dialogConfig.confirm,
        data: { confirmation: SaveDataConfirmationConfig },
      })
      .afterClosed()
      .subscribe((confirm: any) => {
        this.close();
      });
  }

  public close(status: boolean = false): void {
    this.dialogRef.close(status);
  }
}
