<div class="calendar-add-audience">
  <div mat-dialog-title>
    <div class="justify-between">
      <h1 class="fw-500">Select {{ type }}</h1>
      <div class="h-24 c-pointer" (click)="close()">
        <img
          class="h-24"
          [src]="icon.closeBlack.src"
          [alt]="icon.closeBlack.alt" />
      </div>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="search">
      <app-input-search-one
        [delete]="true"
        (onSearch)="searchAudience($event)"></app-input-search-one>
    </div>
    <ng-container *ngIf="!isEmpty; else emptyState">
      <div class="pt-16">
        <div>
          <app-button-add
            [round]="true"
            [label]="'Create New User'"
            (onAdd)="createNewUser()"></app-button-add>
        </div>
      </div>
      <div class="pt-16">
        <h6 class="pb-8 fw-500 tc-black">All {{ type }}</h6>
        <div class="d-flex f-column gap-2 scrolled-group roam-scrollbar">
          @for (data of MOCK_DATA; track data) {
            <div (click)="select(data)" class="list-item justify-start gap-10">
              <img [src]="data.imageUrl" alt="" />
              <div>
                <div class="fw-500 font-14px">{{ data.name }}</div>
                <div class="fw-400 font-12px tc-dust">{{ data.email }}</div>
              </div>
            </div>
          }
        </div>
      </div>
    </ng-container>
  </div>

  <mat-dialog-actions class="mat-dialog-roam-action pt-16 pb-16 pr-28">
    <button mat-button mat-dialog-close class="btn-close c-pointer">
      <span class="text-underline"> Cancel </span>
    </button>
    <app-roam-button
      [class]="'btn btn-primary bold pr-24 pl-24 h-45'"
      [label]="'Select'">
    </app-roam-button>
  </mat-dialog-actions>
</div>

<ng-template #emptyState>
  <div style="min-height: 45vh">
    <app-empty-state
      [icon]="icon.house.src"
      [title]="'Result not found'"
      [titleClass]="'fw-500 tc-black'"></app-empty-state>
  </div>
</ng-template>
