export const MaintenanceTableLabelConst = [
  "Work Order Title",
  "Vendor Employee",
  "Customer",
  "Category",
  "Status",
  "Priority",
  "Action",
  "",
];

export const MaintenanceTableKeyConst = [
  "description",
  "vendor",
  "relatedTo",
  "category",
  "workOrderStatusName",
  "priorityName",
  "action",
  "selection",
];

export const ServiceTaskTableLabelConst = [
  "Service Task",
  "Inspection Form",
  "Estimated Duration",
  "Assigned User",
  "Linked Asset",
  "Action",
];

export const ServiceTaskTableKeyConst = [
  "serviceTask",
  "inspectionForm",
  "estimatedDuration",
  "assignUser",
  "linkedAsset",
  "action",
];

export const ServiceTaskPreviewTableLabelConst = [
  "Service Task",
  "Status",
  "Description",
  "Inspection Form",
  "Estimated Duration",
];

export const ServiceTaskPreviewTableKeyConst = [
  "serviceTask",
  "status",
  "description",
  "inspectionForm",
  "estimatedDuration",
];
