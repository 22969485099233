import { IConfirmationDialog } from "@app/shared/interfaces/confirmation-dialog.interface";

export const SaveDataConfirmationConfig: IConfirmationDialog = {
  title: 'Confirm Exit',
  body: `
        <div class="fw-400">Oops! It looks like you haven't finished entering all the required information. Are you sure you want to exit?"</div>`
  ,
  btnCancel: 'Exit Without Saving',
  btnSubmit: 'Save & Exit',
  btnCancelClass: 'tc-black'
}
