<div class="roam-std-page">
  <div class="roam-std-page-body">
    <div class="justify-center">
      <h1 class="big-title">It’s easy to add a new work order</h1>
    </div>
    <div class="justify-center f-column">
      <div
        class="caption-list"
        *ngFor="let banner of addWorkOrderBannerCaption; let number = index">
        <div class="d-flex gap-12">
          <h3 class="font-20px fw-600">
            {{ number + 1 }}
          </h3>
          <div>
            <h3 class="font-20px fw-600">
              {{ banner.title }}
            </h3>
            <div class="tc-grey">
              {{ banner.caption }}
            </div>
          </div>
        </div>

        <div>
          <span class="d-flex" [inlineSVG]="banner.icon"></span>
        </div>
      </div>
    </div>
  </div>
  <footer>
    <app-roam-button
      [loader]="false"
      [label]="'Get Started'"
      (onClick)="onClick.emit()"></app-roam-button>
  </footer>
</div>
