import { Component, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { iconLib } from "@app/core/const/roam-icon";
import { SelectionGroupComponent } from "../../shared/dialog/selection-group/selection-group.component";
import { dialogConfig } from "@app/core/const/dialog.const";

@Component({
  selector: "app-add-work-customer-details",
  templateUrl: "./add-work-customer-details.component.html",
  styles: `
    .form-contact {
      display: grid;
      gap: 20px;
    }
    .reverse {
      &::ng-deep .align-center {
        flex-direction: row-reverse;
      }
    }
  `,
})
export class AddWorkCustomerDetailsComponent {
  icon = iconLib;
  disabledBilling = signal<boolean>(false);

  constructor(private dialog: MatDialog) {}

  sameAs(status: boolean) {
    if (status) {
      this.disabledBilling.set(true);
    } else {
      this.disabledBilling.set(false);
    }
  }

  orgName = "";
  custName = "";
  propertyName = "";
  clearSelection(type: string) {
    if (type === "org") this.orgName = "";
    if (type === "cust") this.custName = "";
    if (type === "property") this.propertyName = "";
  }
  openSelection(type: string) {
    this.dialog.open(SelectionGroupComponent, {
      ...dialogConfig.mdDialog,
      data: type,
    });
  }
}
