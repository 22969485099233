<div mat-dialog-title>
  <div class="justify-between">
    <h2 class="fw-500">Schedule Work Order “{{ data.task.description }}”</h2>
    <div class="c-pointer" mat-dialog-close>
      <img [src]="icon.closeBlack.src" [alt]="icon.closeBlack.alt" />
    </div>
  </div>
</div>
<div class="mat-dialog-roam-content">
  <form [formGroup]="form" class="form-date">
    <div>
      <div class="fw-400 pb-10">Schedule Start Time</div>
      <div class="form-date">
        <div>
          <app-roam-datepicker
            formControlName="startDate"
            [name]="'Start Date'"></app-roam-datepicker>
        </div>
        <div>
          <app-roam-select
            formControlName="startTime"
            placeholder="Start Time"
            [items]="timesList" />
        </div>
      </div>
    </div>
    <div>
      <div class="fw-400 pb-10">Schedule End Time</div>
      <div class="form-date">
        <div>
          <app-roam-datepicker
            formControlName="endDate"
            [name]="'End Date'"></app-roam-datepicker>
        </div>
        <div>
          <app-roam-select
            formControlName="endTime"
            placeholder="End Time"
            [items]="timesList" />
        </div>
      </div>
    </div>
  </form>
  <div class="notice">
    This work order is due by {{ data.task.due ?? "-" }}.
  </div>
  <hr class="m-0" />
  <div class="fw-600">Employee to be Assigned</div>
  <div class="justify-start gap-10">
    <app-image-view
      [height]="'40px'"
      [width]="'40px'"
      [image]="data.cust.imgUrl"
      [rounded]="true"></app-image-view>
    <div>
      <div class="fw-600">{{ data.cust.name }}</div>
      <div class="tc-dust">{{ data.cust.role }}</div>
    </div>
  </div>
</div>

<mat-dialog-actions
  class="mat-dialog-roam-action gap-10"
  [style.justify-content]="'space-between !important'">
  <button mat-button mat-dialog-close class="btn-close c-pointer">
    <span class="text-underline"> Cancel </span>
  </button>
  <app-roam-button
    [class]="'btn btn-primary bold pr-24 pl-24 h-45'"
    [label]="'Schedule'"
    (onClick)="submit()">
  </app-roam-button>
</mat-dialog-actions>
