<div class="shortcut-menu-header">
  <p class="title">Create New</p>
  <img
    src="/assets/svg/remove-grey-icon.svg"
    alt="Remove Icon"
    class="c-pointer"
    (click)="closeShortcutMenuHandler()"
  />
</div>
<section class="shortcut-menu">
  <div class="shortcut-menu-list">
    <p class="title">Contacts</p>

    <div class="shortcut-menu-card">
      <app-shortcut-menu-card
        *ngFor="let item of shortcutMenus.Contact"
        [shortcut]="item"
        (click)="!item.comingSoon ? borderColorChange(item) : null"
      ></app-shortcut-menu-card>
    </div>
  </div>
  <div class="shortcut-menu-list">
    <p class="title">Tasks & Maintenance</p>

    <div class="shortcut-menu-card">
      <app-shortcut-menu-card
        *ngFor="let item of shortcutMenus.TasksAndMaintenance"
        [shortcut]="item"
        (click)="!item.comingSoon ? borderColorChange(item) : null"
      ></app-shortcut-menu-card>
    </div>
  </div>
  <div class="shortcut-menu-list">
    <p class="title">Vendor Transactions</p>

    <div class="shortcut-menu-card">
      <app-shortcut-menu-card
        *ngFor="let item of shortcutMenus.VendorTransactions"
        [shortcut]="item"
        (click)="!item.comingSoon ? borderColorChange(item) : null"
      ></app-shortcut-menu-card>
    </div>
  </div>
  <div class="shortcut-menu-list">
    <p class="title">Customer Transactions</p>

    <div class="shortcut-menu-card">
      <app-shortcut-menu-card
        *ngFor="let item of shortcutMenus.CustomerTransactions"
        [shortcut]="item"
        (click)="!item.comingSoon ? borderColorChange(item) : null"
      ></app-shortcut-menu-card>
    </div>
  </div>
  <div class="shortcut-menu-list">
    <p class="title">Other Transactions</p>

    <div class="shortcut-menu-card">
      <app-shortcut-menu-card
        *ngFor="let item of shortcutMenus.OtherTransactions"
        [shortcut]="item"
        (click)="!item.comingSoon ? borderColorChange(item) : null"
      ></app-shortcut-menu-card>
    </div>
  </div>
  <div class="shortcut-menu-list">
    <p class="title">Reporting</p>

    <div class="shortcut-menu-card">
      <app-shortcut-menu-card
        *ngFor="let item of shortcutMenus.Reporting"
        [shortcut]="item"
        (click)="!item.comingSoon ? borderColorChange(item) : null"
      ></app-shortcut-menu-card>
    </div>
  </div>
  <div class="shortcut-menu-list">
    <p class="title">Properties</p>

    <div class="shortcut-menu-card">
      <app-shortcut-menu-card
        *ngFor="let item of shortcutMenus.Properties"
        [shortcut]="item"
        (click)="!item.comingSoon ? borderColorChange(item) : null"
      ></app-shortcut-menu-card>
    </div>
  </div>
  <div class="shortcut-menu-list">
    <p class="title">Communications</p>

    <div class="shortcut-menu-card">
      <app-shortcut-menu-card
        *ngFor="let item of shortcutMenus.AnnouncementMenu"
        [shortcut]="item"
        (click)="!item.comingSoon ? borderColorChange(item) : null"
      ></app-shortcut-menu-card>
    </div>
  </div>
</section>
