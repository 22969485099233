import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-add-work-order-start-page",
  templateUrl: "./add-work-order-start-page.component.html",
  styleUrls: ["./add-work-order-start-page.component.scss"],
})
export class AddWorkOrderStartPageComponent {
  @Output()
  public onClick: EventEmitter<Event> = new EventEmitter<Event>();

  public addWorkOrderBannerCaption: {
    title: string;
    caption: string;
    icon: string;
  }[] = [
    {
      title: "Create customer and work order details",
      caption:
        "Record specific information about the customer and the details of the work order.",
      icon: "./assets/svg/il-folder.svg",
    },
    {
      title: "Schedule and assign",
      caption:
        "Assign the work order to appropriate individuals or teams for execution.",
      icon: "./assets/svg/il-report.svg",
    },
    {
      title: "Preview and finish up",
      caption:
        "Review the entered customer and work order details before creating the work order.",
      icon: "./assets/svg/il-submit.svg",
    },
  ];
}
