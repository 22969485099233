import { Component, ElementRef, Inject } from "@angular/core";
import { iconLib } from "@app/core/const/roam-icon";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { SharedModule } from "@app/shared/shared.module";

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule],
  selector: "app-selection-group",
  templateUrl: "./selection-group.component.html",
  styles: [
    `
      .list-item {
        cursor: pointer;
        transition: all 0.1s ease-in;
        padding: 8px;
        border-radius: 8px;
        user-select: none;

        &:hover {
          background-color: #ddd;
        }
        img {
          border-radius: 50%;
          width: 40px;
          aspect-ratio: 1;
          object-fit: cover;
        }
      }
    `,
  ],
})
export class SelectionGroupComponent {
  public icon = iconLib;
  public isEmpty: boolean = false;

  MOCK_DATA = [
    {
      imageUrl:
        "https://png.pngtree.com/background/20230525/original/pngtree-cute-anime-girl-wearing-flowers-picture-image_2735301.jpg",
      name: "Rommy",
      email: "Rommy@roamstay.com",
    },
    {
      imageUrl:
        "https://i.pinimg.com/736x/ff/5d/aa/ff5daa2bcb5864028734a3bf71ed7654.jpg",
      name: "Lisa Black Pink",
      email: "lisa@roamstay.com",
    },
    {
      imageUrl:
        "https://heloindonesia.com/photo/upload/2024/1708482278_1-org.jpg",
      name: "Jisoo  Black Pink",
      email: "jisoo@roamstay.com",
    },
    {
      imageUrl:
        "https://www.nme.com/wp-content/uploads/2023/09/jennie-working-on-solo-music-220923.jpg",
      name: "Jennie  Black Pink",
      email: "jennie@roamstay.com",
    },
    {
      imageUrl:
        "https://e1.pxfuel.com/desktop-wallpaper/600/193/desktop-wallpaper-rose-blackpink-cute-in-2020-blackpink-girl-rose.jpg",
      name: "Rose Black Pink",
      email: "rose@roamstay.com",
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<SelectionGroupComponent>,
    public elementRef: ElementRef,
    @Inject(MAT_DIALOG_DATA) public type: string
  ) {}

  public close(): void {
    this.dialogRef.close();
  }

  public createNewUser() {}

  public searchAudience(search: string): void {
    this.isEmpty = !!search;
  }

  public select(data: any) {
    this.dialogRef.close(data);
  }
}
