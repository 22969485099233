import { IShortCut } from "../data";
import { NewViolationComponent } from "@app/pages/violation/pages/new-violation/new-violation.component";
import { NewModificationComponent } from "@app/pages/modifications/pages/new-modification/new-modification.component";
import { AddNewViolationComponent } from "@app/pages/reporting/dialog/add-new-violation/add-new-violation.component";
import { AddNewModificationComponent } from "@app/pages/reporting/dialog/add-new-modification/add-new-modification.component";

const ContactMenu: IShortCut[] = [
  {
    id: "customer-account",
    icon: "/assets/svg/shortcut-menu/customer-account.svg",
    title: "Customer Account",
    focus: false,
    comingSoon: false,
    contactDialog: "customer",
  },

  {
    id: "tenant",
    icon: "/assets/svg/shortcut-menu/tenant.svg",
    title: "Tenant",
    focus: false,
    comingSoon: false,
    contactDialog: "tenant",
  },
  {
    id: "owner",
    icon: "/assets/svg/shortcut-menu/owner.svg",
    title: "Owner",
    focus: false,
    comingSoon: false,
    contactDialog: "owner",
  },
  {
    id: "vendor",
    icon: "/assets/svg/shortcut-menu/vendor.svg",
    title: "Vendor",
    focus: false,
    comingSoon: false,
    contactDialog: "vendor",
  },
  {
    id: "employee",
    icon: "/assets/svg/shortcut-menu/employee.svg",
    title: "Employee",
    focus: false,
    comingSoon: false,
    contactDialog: "employee",
  },
];

const TasksAndMaintenanceMenu: IShortCut[] = [
  {
    id: "task",
    icon: "/assets/svg/shortcut-menu/task.svg",
    title: "Task",
    focus: false,
  },
  {
    id: "work-order",
    icon: "/assets/svg/shortcut-menu/work-order.svg",
    title: "Work Order",
    focus: false,
    comingSoon: false,
  },
  // {
  //   id: "inspection",
  //   icon: "/assets/svg/shortcut-menu/inspection.svg",
  //   title: "Inspection",
  //   focus: false,
  //   comingSoon: true,
  // },
];

const VendorTransactionsMenu: IShortCut[] = [
  {
    id: "create-bill",
    icon: "/assets/svg/shortcut-menu/create-bill.svg",
    title: "Create Bill",
    focus: false,
    financialKeyDialog: "bills",
  },
  {
    id: "pay-bills",
    icon: "/assets/svg/shortcut-menu/pay-bill.svg",
    title: "Pay Bills",
    focus: false,
    financialKeyDialog: "bill-payments",
  },
  {
    id: "add-credit",
    icon: "/assets/svg/shortcut-menu/add-credit.svg",
    title: "Add Vendor Credit",
    focus: false,
    comingSoon: true,
  },
  {
    id: "management-fees",
    icon: "/assets/svg/shortcut-menu/management-fees.svg",
    title: "Management Fees",
    focus: false,
    comingSoon: true,
  },
];

const CustomerTransactionsMenu: IShortCut[] = [
  {
    id: "create-invoice",
    icon: "/assets/svg/shortcut-menu/create-invoice.svg",
    title: "Create Invoice",
    focus: false,
    financialKeyDialog: "invoices",
  },
  {
    id: "receive-payment",
    icon: "/assets/svg/shortcut-menu/receive-payment.svg",
    title: "Receive Payment",
    focus: false,
    financialKeyDialog: "received-payments",
  },
  {
    id: "credit-memo",
    icon: "/assets/svg/shortcut-menu/credit-memo.svg",
    title: "Credit Memo",
    focus: false,
    financialKeyDialog: "credit-memos",
    comingSoon: true,
  },
  {
    id: "new-charge",
    icon: "/assets/svg/shortcut-menu/new-charge.svg",
    title: "New Charge",
    focus: false,
    financialKeyDialog: "charges",
    comingSoon: true,
  },
];

const OtherTransactionsMenu: IShortCut[] = [
  {
    id: "journal-entry",
    icon: "/assets/svg/shortcut-menu/journal-entry.svg",
    title: "Journal Entry",
    focus: false,
    financialKeyDialog: "journal-entries",
    comingSoon: true,
  },
  {
    id: "bank-transfer",
    icon: "/assets/svg/shortcut-menu/bank-transfer.svg",
    title: "Bank Transfer",
    focus: false,
    financialKeyDialog: "transfers",
    comingSoon: true,
  },
  {
    id: "bank-deposit",
    icon: "/assets/svg/shortcut-menu/bank-deposit.svg",
    title: "Bank Deposit",
    focus: false,
    financialKeyDialog: "deposits",
    comingSoon: true,
  },
  {
    id: "expenses",
    icon: "/assets/svg/shortcut-menu/expense.svg",
    title: "Expense",
    focus: false,
    financialKeyDialog: "expenses",
    comingSoon: true,
  },
  {
    id: "check",
    icon: "/assets/svg/shortcut-menu/check.svg",
    title: "Check",
    focus: false,
    financialKeyDialog: "checks",
    comingSoon: true,
  },
];

const ReportingMenu: IShortCut[] = [
  {
    id: "violation",
    icon: "/assets/svg/shortcut-menu/violation.svg",
    title: "Violation",
    focus: false,
    reportDialog: AddNewViolationComponent,
  },
  {
    id: "modification",
    icon: "/assets/svg/shortcut-menu/modification.svg",
    title: "Modification",
    focus: false,
    reportDialog: AddNewModificationComponent,
  },
  {
    id: "inspection",
    icon: "/assets/svg/shortcut-menu/inspection.svg",
    title: "Inspection",
    focus: false,
    comingSoon: true,
  },
  {
    id: "motion",
    icon: "/assets/svg/shortcut-menu/motion.svg",
    title: "Motion",
    focus: false,
    comingSoon: true,
  },
  {
    id: "meeting",
    icon: "/assets/svg/shortcut-menu/meeting.svg",
    title: "Meeting",
    focus: false,
    comingSoon: true,
  },
  {
    id: "survey",
    icon: "/assets/svg/shortcut-menu/survey.svg",
    title: "Survey",
    focus: false,
    comingSoon: true,
  },
];

const PropertiesMenu: IShortCut[] = [
  {
    id: "association",
    icon: "/assets/svg/shortcut-menu/association.svg",
    title: "Association",
    focus: false,
    comingSoon: false,
  },
  {
    id: "unit",
    icon: "/assets/svg/shortcut-menu/unit.svg",
    title: "Unit",
    focus: false,
    comingSoon: false,
  },
  {
    id: "property",
    icon: "/assets/svg/shortcut-menu/property.svg",
    title: "Property",
    focus: false,
    comingSoon: true,
  },
];

const AnnouncementMenu: IShortCut[] = [
  {
    id: "announcement",
    icon: "/assets/svg/shortcut-menu/announcement.svg",
    title: "Announcement",
    focus: false,
    comingSoon: true,
  },
  // {
  //   id: "email",
  //   icon: "/assets/svg/contact/email.svg",
  //   title: "Email Blast",
  //   focus: false,
  //   comingSoon: true,
  // },
];

export const ShortCutMenus = {
  Contact: ContactMenu,
  TasksAndMaintenance: TasksAndMaintenanceMenu,
  VendorTransactions: VendorTransactionsMenu,
  CustomerTransactions: CustomerTransactionsMenu,
  OtherTransactions: OtherTransactionsMenu,
  Reporting: ReportingMenu,
  Properties: PropertiesMenu,
  AnnouncementMenu: AnnouncementMenu,
};
