import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { iconLib } from "@app/core/const/roam-icon";
import { ScheduleStore } from "../maintenance-dialog-schedule/table-schedule/schedule.store";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ITask } from "../maintenance-dialog-schedule/table-schedule/schedule.interface";
import { ScheduleService } from "../maintenance-dialog-schedule/table-schedule/schedule.service";

@Component({
  selector: "app-maintenance-dialog-add-schedule",
  templateUrl: "./maintenance-dialog-add-schedule.component.html",
  styleUrls: ["./maintenance-dialog-add-schedule.component.scss"],
})
export class MaintenanceDialogAddScheduleComponent {
  icon = iconLib;
  timesList: TTimeList[] = [];
  form!: FormGroup;

  constructor(
    private fb: FormBuilder,
    public scheduleStore: ScheduleStore,
    public dialogRef: MatDialogRef<MaintenanceDialogAddScheduleComponent>,
    private scheduleService: ScheduleService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      startTime: [0],
      endTime: [0],
      startDate: [null],
      endDate: [null],
    });
    for (let i = 0; i < this.scheduleStore.stateTimes().length; i++) {
      let time = this.scheduleStore.stateTimes()[i];
      let data = {
        id: i,
        name: time,
      };
      this.timesList.push(data);
    }
    if (this.data && this.data.time != "") {
      let indexStart = this.timesList.findIndex(x => x.name == this.data.time);
      this.form.patchValue({
        startTime: this.timesList[indexStart].id,
        endTime: this.timesList[indexStart + 2].id,
        startDate: this.data.currDate,
        endDate: this.data.currDate,
      });
    }
  }

  submit() {
    const StartTime = this.scheduleService
      .convertToDate(
        this.form.get("startDate")?.value,
        this.timesList.find(x => x.id === this.form.get("startTime")?.value)
          ?.name!
      )
      .toISOString();
    const EndTime = this.scheduleService
      .convertToDate(
        this.form.get("endDate")?.value,
        this.timesList.find(x => x.id === this.form.get("endTime")?.value)
          ?.name!
      )
      .toISOString();

    let dataTask: ITask = {
      taskId: this.scheduleStore.stateTasks().length + 1,
      employeeId: this.data.cust.id,
      startTime: StartTime,
      endTime: EndTime,
      task: this.data.task.description,
      detailTask: this.data.task.text,
      customerName: "Mark Gold",
      house: "1234 Anesee Lake",
      location: "9679 46th Ave SW, WA, USA",
      dueDate: "12/07/2024",
      createdDate: new Date().toISOString(),
    };
    this.scheduleStore.stateTasks.update(x => {
      return [...x, dataTask];
    });
    console.log(dataTask);
    this.dialogRef.close(true);
  }
}

type TTimeList = {
  id: number;
  name: string;
};
