<div>
  <app-roam-card [title]="'Work Order Details'">
    <div class="set-grid set-grid-2">
      <app-roam-input name="Work Order Title" />
      <app-roam-select placeholder="Work Order Category" />
      <app-roam-select placeholder="Work Order Priority" />
    </div>
    <h6 class="title mt-8 mb-8">Work Order Description</h6>
    <quill-editor
      class="roam-editor"
      [placeholder]="''"
      [modules]="editorConfig">
    </quill-editor>
  </app-roam-card>

  <app-roam-card [title]="'Other Details'">
    <div class="set-grid set-grid-3">
      <div>
        <h6 class="title mt-8 mb-8">Type of Service</h6>
        <div class="reverse hovered">
          @for (data of LIST_SERVICES(); track data) {
            <mat-checkbox>
              {{ data }}
            </mat-checkbox>
          }
        </div>
      </div>
      <app-roam-input name="Access Code" />
      <div>
        <h6 class="title mt-8 mb-8">Pet on Site</h6>
        <mat-radio-group class="grid">
          <mat-radio-button value="1">Yes</mat-radio-button>
          <mat-radio-button value="2">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="set-grid set-grid-3 mt-10">
      <app-roam-input name="Customer Reference" />
      <app-roam-input name="Cost Estimate" />
      <app-roam-input name="Actual Cost" />
    </div>
    <!-- <div class="">
      <h6 class="title mt-8 mb-8">Attachments</h6>
      <app-file-uploader
        containerText="Drag Here"
        [(data)]="attachments"
        [config]="modelConfig()" />
    </div> -->
  </app-roam-card>

  <app-roam-card [title]="'Line Items'">
    <div class="set-grid set-grid-3">
      <app-roam-select placeholder="Select Expense Account" />
      <app-roam-select placeholder="Select Vendor Account" />
      <app-roam-select placeholder="Select Terms" />
    </div>
    <hr />
    <h6 class="title mt-8 mb-8">Item Details</h6>
    <form [formGroup]="form">
      <div formArrayName="itemList" class="mb-10 list-item">
        <div
          *ngFor="let data of formItemList.controls; let i = index"
          [formGroupName]="i">
          <div class="pb-10 justify-between">
            <div>item {{ i + 1 }}</div>
            <roam-icon
              *ngIf="i != 0"
              name="bin"
              color="warning"
              class="c-pointer"
              (onClick)="removeItem(i)" />
          </div>
          <div class="item-details">
            <app-roam-select
              formControlName="itemCategory"
              placeholder="Item Category" />
            <app-roam-input
              formControlName="serviceName"
              name="Enter Service Name" />
            <app-roam-select
              formControlName="customerBill"
              placeholder="Customer Bill" />
            <app-roam-input formControlName="qty" name="Qty" />
            <app-roam-input formControlName="unitPrice" name="Unit Price" />
            <app-roam-input formControlName="amount" name="Amount" />
          </div>
        </div>
      </div>
    </form>

    <app-button-add
      [round]="true"
      [label]="'Add Item'"
      (onAdd)="addNewItem()"></app-button-add>
    <hr />
    <div class="justify-end fw-600 font-20px">Total $0.00</div>
  </app-roam-card>

  <app-roam-card [title]="'Service Task'">
    <div class="set-grid gap-10" actRef>
      <app-button-add
        [round]="true"
        [label]="'Add Task'"
        (onAdd)="addTask()"></app-button-add>
      <button class="btn btn-o-primary">Reorder</button>
      <button class="btn btn-o-primary">Remove All</button>
    </div>
    <app-table
      [httpPagination]="true"
      [isLoading]="isLoading"
      [tableConfig]="tableConfig"
      [emptyState]="emptyText"
      (mouseOverChange)="onMouseOverChange($event)"
      (selectData)="navigateToDetail($event.id)"
      (pageChange)="onPageChange($event)">
      <ng-template #customTemplate let-key="key" let-data="data">
        <ng-container *ngIf="key === 'serviceTask'">
          <div class="fw-500">
            {{ data.serviceTask }}
          </div>
          <div class="fw-300 tc-dust">
            {{ data.serviceDesc }}
          </div>
        </ng-container>
        <ng-container *ngIf="key === 'assignUser'">
          <div class="text-underline">
            {{ data.assignUser }}
          </div>
        </ng-container>
        <ng-container *ngIf="key === 'linkedAsset'">
          <div class="text-underline tc-primary c-pointer">
            {{ data.linkAsset }}
          </div>
        </ng-container>

        <ng-container *ngIf="key === 'action'">
          <button
            aria-label="show card menu"
            [matMenuTriggerFor]="tableMenu"
            [matMenuTriggerData]="{ data: data }"
            class="more-button"
            mat-icon-button
            type="button">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </ng-container>
      </ng-template>
    </app-table>
  </app-roam-card>

  <app-roam-card [title]="'Work Order Attachment'">
    <div class="">
      <h6 class="title mb-8">Attachments</h6>
      <app-file-uploader
        containerText="Drag Here"
        [(data)]="attachments"
        [config]="modelConfig()" />
    </div>
  </app-roam-card>
</div>

<mat-menu class="board-card-menu" #tableMenu="matMenu">
  <ng-template matMenuContent let-data="data">
    <button mat-menu-item (click)="copyTask(data)">
      <div class="align-center gap-8 font-14 fw-500">
        <div class="d-flex" [inlineSVG]="'./assets/svg/card-two.svg'"></div>
        <div>Copy</div>
      </div>
    </button>
    <button mat-menu-item (click)="editTask(data)">
      <div class="align-center gap-8 font-14 fw-500">
        <div class="d-flex" [inlineSVG]="'./assets/svg/roam/edit.svg'"></div>
        <div>Edit</div>
      </div>
    </button>
    <button mat-menu-item (click)="deleteTask(data)">
      <div class="align-center gap-8 font-14 fw-500">
        <div class="d-flex" [inlineSVG]="'./assets/svg/roam/bin.svg'"></div>
        <div>Delete</div>
      </div>
    </button>
  </ng-template>
</mat-menu>
