<div class="table-scheduler">
  <div class="schedule-header">
    <div></div>
    <div class="justify-center gap-10">
      <div
        (click)="prevDay()"
        class="btn-date"
        [inlineSVG]="'./assets/svg/ic-left.svg'"></div>
      <div class="schedule-date">{{ today | date }}</div>
      <div
        (click)="nextDay()"
        class="btn-date"
        [inlineSVG]="'./assets/svg/ic-right.svg'"></div>
    </div>
    <div class="justify-end">
      <button
        (click)="goToday()"
        class="btn btn-o-glight fw-600 tc-black pl-24 pr-24">
        Today
      </button>
    </div>
  </div>
  <div class="schedule">
    <div class="schedule-body" #scheduleBody>
      <div class="side-bar-schedule">
        <div class="tc-dust side-corner bg-glight">Employee</div>
        <div
          class="customer-item"
          *ngFor="let cust of scheduleStore.stateEmployees()">
          <app-image-view
            [height]="'40px'"
            [width]="'40px'"
            [image]="cust.imgUrl"
            [rounded]="true"></app-image-view>
          <div>
            <div class="cust-name">{{ cust.name }}</div>
            <div class="cust-role">{{ cust.role }}</div>
            <div class="cust-freetime">({{ cust.freeTime }}h free)</div>
          </div>
        </div>
      </div>

      <div class="grid-time">
        <div class="schedule-time">
          <div class="out-work1">Out Of Working Time</div>
          <div
            class="tc-dust position-relative"
            *ngFor="let time of scheduleStore.stateTimesHeader(); index as i">
            <div *ngIf="i > 7 && i < 18" class="time-item bg-glight">
              {{ time }}
            </div>
          </div>
          <div class="out-work2">Out Of Working Time</div>
        </div>
        <div class="drag-place" #containerTableContent>
          <app-card-slot
            *ngFor="let data of scheduleStore.stateTasks()"
            [dataTask]="data"
            [containerTableContent]="containerTableContent"></app-card-slot>

          <div
            class="tc-dust position-relative"
            *ngFor="let time of scheduleStore.stateTimesHeader()">
            <div
              (mouseenter)="showHoverDiv($event, time)"
              (mouseleave)="hideHoverDiv()"
              (click)="setTask(cust, time)"
              [ngClass]="{ 'slot-item': true, inactive: getActive(cust, time) }"
              *ngFor="let cust of scheduleStore.stateEmployees()"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #hoverDivTemplate>
  <div class="detail-hour">{{ hoverData }}</div>
</ng-template>
