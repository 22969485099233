<div class="flex-column-height">
  <app-header-roam-one
    [enableClose]="startPage"
    (onConfirm)="confirmCloseDialog()"
    (onClose)="close()">
  </app-header-roam-one>

  <div class="flex-1">
    <app-add-work-order-start-page
      *ngIf="startPage"
      (onClick)="openAddLeaseForm()" />

    <ng-container *ngIf="!startPage">
      <app-roam-layout-content-fill
        [fullPage]="currentPage.id === 'schedule-and-assign'">
        <ng-container header>
          <header>
            <div class="align-center gap-12">
              <span class="d-flex" [inlineSVG]="currentPage.icon"></span>
              <h2 class="title">{{ currentPage.label }}</h2>
            </div>
            <div class="step-count">Step {{ currentPage.step }}</div>
          </header>
        </ng-container>

        <ng-container content>
          <ng-container *ngIf="!isLoading; else spinner">
            <ng-container
              *ngComponentOutlet="currentPage.component"></ng-container>
          </ng-container>
        </ng-container>

        <ng-container footer>
          <!-- STEPPER -->
          <div>
            <div class="roam-stepper-bar">
              <ul>
                <li
                  *ngFor="let stepper of newWorkOrderPages; let index = index"
                  class="bar"
                  [class.active]="stepper.active"></li>
              </ul>
            </div>
          </div>
          <!-- /STEPPER -->

          <footer class="footer">
            <div
              [style.visibility]="currentIndex === 0 ? 'hidden' : 'visible'"
              class="text-underline font-14px fw-600 c-pointer"
              (click)="prevPage()">
              Back
            </div>
            <div class="d-flex gap-16">
              <app-roam-button
                [label]="buttonNextText"
                (onClick)="nextPage()"
                [isLoading]="isSubmit"
                [disabled]="false"></app-roam-button>
            </div>
          </footer>
        </ng-container>
      </app-roam-layout-content-fill>
    </ng-container>
  </div>
</div>

<ng-template #spinner>
  <div class="p-relative h-200">
    <app-loader [dark]="true"></app-loader>
  </div>
</ng-template>
