<!-- [cdkDragConstrainPosition]="constrainPosition" // for snaping to grid -->
<div
  [cdkDragFreeDragPosition]="dragPosition"
  cdkDrag
  [cdkDragBoundary]="dragBoundary"
  (cdkDragEnded)="dragPositionEnd($event)"
  [class]="'card-slot card-slot-' + dataTask.taskId">
  <div
    #containerSlot
    class="body-slot"
    (mouseenter)="showHoverDiv()"
    (mouseleave)="hideHoverDiv()">
    <div class="fw-600 pb-10 item font-16px">{{ dataTask.task }}</div>
    <div class="item">
      <span [inlineSVG]="iconTM.calendar.src"></span>
      <span
        >{{ dataTask.startTime | date: "M/d/yyyy hh:mma" }} ->
        {{ dataTask.endTime | date: "M/d/yyyy hh:mma" }}</span
      >
    </div>
    <div class="item">
      <span [inlineSVG]="iconTM.time.src"></span>
      <span>Due by 06/30/2024</span>
    </div>
    <div class="item">
      <span [inlineSVG]="iconTM.owner.src"></span>
      <span>{{ dataTask.customerName }}</span>
    </div>
    <div class="item">
      <span [inlineSVG]="iconTM.door.src"></span>
      <span>{{ dataTask.house }}</span>
    </div>
    <div class="item">
      <span [inlineSVG]="iconTM.location.src"></span>
      <span>{{ dataTask.location }}</span>
    </div>

    <!-- FOR HANDLE RESIZE -->

    <span
      #dragHandleRight
      class="dragHandle right"
      cdkDrag
      [cdkDragLockAxis]="'x'"
      [cdkDragBoundary]="dragBoundary"
      [cdkDragStartDelay]="1"
      (cdkDragMoved)="dragResize(dragHandleRight, $event)"
      (cdkDragEnded)="dragResizeEnd($event)">
    </span>
  </div>
</div>

<ng-template #hoverDivTemplate>
  <div class="tool-tip-detail">
    <div class="fw-600 pb-10 item font-16px">{{ dataTask.task }}</div>
    <div class="item">
      <span [inlineSVG]="iconTM.calendar.src"></span>
      <span
        >{{ dataTask.startTime | date: "M/d/yyyy hh:mma" }} ->
        {{ dataTask.endTime | date: "M/d/yyyy hh:mma" }}</span
      >
    </div>
    <div class="item">
      <span [inlineSVG]="iconTM.time.src"></span>
      <span>Due by 06/30/2024</span>
    </div>
    <div class="item">
      <span [inlineSVG]="iconTM.owner.src"></span>
      <span>{{ dataTask.customerName }}</span>
    </div>
    <div class="item">
      <span [inlineSVG]="iconTM.door.src"></span>
      <span>{{ dataTask.house }}</span>
    </div>
    <div class="item">
      <span [inlineSVG]="iconTM.location.src"></span>
      <span>{{ dataTask.location }}</span>
    </div>
  </div>
</ng-template>
