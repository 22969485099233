import { Component, computed, inject, signal } from "@angular/core";

import { iconLib } from "@app/core/const/roam-icon";
import { EditorConfig } from "@app/core/const/editor.const";
import { QuillModules } from "ngx-quill";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { TableConfigModel } from "@app/shared/components/table/model";
import {
  ServiceTaskTableKeyConst,
  ServiceTaskTableLabelConst,
} from "../../shared/const/maintenance-table-config.const";
import { IEmptyState } from "@app/shared/interfaces/empty-state-text.interface";
import { PageEvent } from "@angular/material/paginator";

@Component({
  selector: "app-add-work-order-details",
  templateUrl: "./add-work-order-details.component.html",
  styleUrl: "./add-work-order-details.component.scss",
})
export class AddWorkOrderDetailsComponent {
  icon = iconLib;
  protected attachments = signal([]);
  public editorConfig: QuillModules = EditorConfig;
  readonly taskId = signal<string>(crypto.randomUUID());
  protected modelConfig = computed(() => {
    return {
      // TODO: Find out how to get this...
      // name: Fox Chase,
      // This should not be required later...
      propertyId: "8282fd8d-249e-493c-8201-a15a646ad10a",
      modelId: this.taskId(),
      model: "task",
    };
  });

  #fb = inject(FormBuilder);
  form: FormGroup;

  createListItem() {
    return this.#fb.group({
      itemCategory: [""],
      serviceName: [""],
      customerBill: [""],
      qty: [""],
      unitPrice: [""],
      amount: [""],
    });
  }
  get formItemList(): FormArray {
    return this.form.get("itemList") as FormArray;
  }

  LIST_SERVICES = signal(["Plumbing", "Electrical", "Cleaning", "Other"]);

  addNewItem() {
    this.formItemList.push(this.createListItem());
  }
  removeItem(index: number) {
    this.formItemList.removeAt(index);
  }

  addTask() {}

  constructor() {
    this.form = this.#fb.group({
      itemList: this.#fb.array([this.createListItem()]),
    });
    this.initTableConfig();
    this.initEmptyState();
  }

  public tableConfig: TableConfigModel = new TableConfigModel();
  public emptyText: IEmptyState = { title: "", subtitle: "" };
  public isLoading = false;

  private initTableConfig() {
    this.tableConfig = new TableConfigModel();
    this.tableConfig.dataKey = ServiceTaskTableKeyConst;
    this.tableConfig.dataLabel = ServiceTaskTableLabelConst;

    this.tableConfig.dataSource = [
      {
        serviceTask: "Electrical",
        serviceDesc: "Electrical Service",
        inspectionForm: "Inspection Form",
        estimatedDuration: "1h",
        assignUser: "Bruno Fernandes",
        linkAsset: "url",
      },
      {
        serviceTask: "Plumbling",
        serviceDesc: "Plumbling Service",
        inspectionForm: "Inspection Form",
        estimatedDuration: "1h",
        assignUser: "Bruno Fernandes",
        linkAsset: "url",
      },
    ];
    this.tableConfig.dataTotal = 2;

    this.tableConfig.dataType["serviceTask"] = "custom";
    this.tableConfig.dataType["assignUser"] = "custom";
    this.tableConfig.dataType["linkedAsset"] = "custom";
    this.tableConfig.dataType["action"] = "custom";
  }

  private initEmptyState(): void {
    this.emptyText.title = "Nothing To Display";
    this.emptyText.subtitle = "No Service Task Found";
  }

  public onMouseOverChange(data: any) {}
  public navigateToDetail(id: string): void {}
  public onPageChange(event: PageEvent) {}
  public copyTask(data: any) {}
  public editTask(data: any) {}
  public deleteTask(data: any) {}
}
