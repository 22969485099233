import { Component } from "@angular/core";
import { TableConfigModel } from "@app/shared/components/table/model";
import { IEmptyState } from "@app/shared/interfaces/empty-state-text.interface";
import {
  ServiceTaskPreviewTableKeyConst,
  ServiceTaskPreviewTableLabelConst,
} from "../../shared/const/maintenance-table-config.const";
import { PageEvent } from "@angular/material/paginator";
import { SAMPLE_FILE } from "@app/shared/mock/detail.mock";
import { NewWorkOrderService } from "../../shared/dialog/maintenance-dialog-new-work-order/new-work-order.service";

@Component({
  selector: "app-add-work-order-preview",
  templateUrl: "./add-work-order-preview.component.html",
  styleUrl: "./add-work-order-preview.component.scss",
})
export class AddWorkOrderPreviewComponent {
  navigateToPage(step: number) {
    this.newWorkService.setToStep(step);
  }

  SAMPLE_FILES = SAMPLE_FILE;

  public tableConfig: TableConfigModel = new TableConfigModel();
  public emptyText: IEmptyState = { title: "", subtitle: "" };
  public isLoading = false;

  private initTableConfig() {
    this.tableConfig = new TableConfigModel();
    this.tableConfig.dataKey = ServiceTaskPreviewTableKeyConst;
    this.tableConfig.dataLabel = ServiceTaskPreviewTableLabelConst;
    this.tableConfig.dataSource = [
      {
        serviceTask: "Electrical",
        status: "Open",
        description: "Electrical Service",
        inspectionForm: "Inspection Form",
        estimatedDuration: "1 Hour",
      },
      {
        serviceTask: "Plumbling",
        status: "Open",
        description: "Plumbling Service",
        inspectionForm: "Inspection Form",
        estimatedDuration: "1 Hour",
      },
    ];
    this.tableConfig.dataTotal = 2;
  }

  private initEmptyState(): void {
    this.emptyText.title = "Nothing To Display";
    this.emptyText.subtitle = "No Service Task Found";
  }

  public onMouseOverChange(data: any) {}
  public navigateToDetail(id: string): void {}
  public onPageChange(event: PageEvent) {}

  constructor(private newWorkService: NewWorkOrderService) {
    this.initTableConfig();
    this.initEmptyState();
  }
}
