<!-- <app-container-dialog-set>
  <ng-container container>
    
  </ng-container>
</app-container-dialog-set> -->
<div>
  <app-roam-card [title]="'Customer Details'">
    <div class="set-grid set-grid-3">
      <div class="p-relative">
        @if (orgName != "") {
          <span class="close-btn" (click)="clearSelection('org')">
            <span
              class="svg-16 d-flex pl-5"
              [inlineSVG]="icon.closeBlack.src"></span>
          </span>
        }

        <input-dropdown
          placeholder="Select Organization"
          [floatingLabel]="false"
          [(ngModel)]="orgName"
          (click)="openSelection('Organization')" />
      </div>
      <div class="p-relative">
        @if (custName != "") {
          <span class="close-btn" (click)="clearSelection('cust')">
            <span
              class="svg-16 d-flex pl-5"
              [inlineSVG]="icon.closeBlack.src"></span>
          </span>
        }

        <input-dropdown
          placeholder="Select Customer"
          [floatingLabel]="false"
          [(ngModel)]="custName"
          (click)="openSelection('Customer')" />
      </div>
      <div class="p-relative">
        @if (propertyName != "") {
          <span class="close-btn" (click)="clearSelection('property')">
            <span
              class="svg-16 d-flex pl-5"
              [inlineSVG]="icon.closeBlack.src"></span>
          </span>
        }

        <input-dropdown
          placeholder="Select Property"
          [floatingLabel]="false"
          [(ngModel)]="propertyName"
          (click)="openSelection('Property')" />
      </div>
    </div>
  </app-roam-card>
  <app-roam-card [title]="'Service Contact Info'">
    <div class="form-contact">
      <div class="set-grid set-grid-2">
        <app-roam-input name="Contact First Name" />
        <app-roam-input name="Contact Last Name" />
      </div>
      <div class="form-contact pr-10">
        <app-roam-input name="Address" />
        <app-roam-input name="City" />
        <app-roam-input name="Landmark" />
      </div>
      <div class="set-grid set-grid-2">
        <app-roam-select placeholder="State / Province / Region" />
        <app-roam-input name="Postal Zip Code" />
      </div>
    </div>
  </app-roam-card>
  <app-roam-card [title]="'Billing Contact Info'">
    <div actRef>
      <app-roam-toggle-slider
        class="reverse"
        (OnChangeToggle)="sameAs($event)"
        [label]="'Same as Service Contact'"></app-roam-toggle-slider>
    </div>
    <div class="form-contact">
      <div class="set-grid set-grid-2">
        <app-roam-input
          [disabled]="disabledBilling()"
          name="Contact First Name" />
        <app-roam-input
          [disabled]="disabledBilling()"
          name="Contact Last Name" />
      </div>
      <div class="form-contact pr-10">
        <app-roam-input [disabled]="disabledBilling()" name="Address" />
        <app-roam-input [disabled]="disabledBilling()" name="City" />
        <app-roam-input [disabled]="disabledBilling()" name="Landmark" />
      </div>
      <div class="set-grid set-grid-2">
        <app-roam-select
          [disabled]="disabledBilling()"
          placeholder="State / Province / Region" />
        <app-roam-input [disabled]="disabledBilling()" name="Postal Zip Code" />
      </div>
    </div>
  </app-roam-card>
</div>
