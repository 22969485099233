import { IPageStepper } from "@app/shared/interfaces/page-stepper.interface";
import { AddWorkCustomerDetailsComponent } from "../../components/add-work-customer-details/add-work-customer-details.component";
import { AddWorkOrderDetailsComponent } from "../../components/add-work-order-details/add-work-order-details.component";
import { AddWorkOrderPreviewComponent } from "../../components/add-work-order-preview/add-work-order-preview.component";
import { AddWorkOrderScheduleComponent } from "../../components/add-work-order-schedule/add-work-order-schedule.component";

export const NewWorkOrderPages: IPageStepper[] = [
  {
    id: "customer-details",
    label: "Customer Details",
    component: AddWorkCustomerDetailsComponent,
    step: 1,
    active: true,
    icon: "./assets/svg/ic-r-user-ava.svg",
  },
  {
    id: "work-order-details",
    label: "Work Order Details",
    component: AddWorkOrderDetailsComponent,
    step: 2,
    active: false,
    icon: "./assets/svg/ic-maintenance.svg",
  },
  {
    id: "schedule-and-assign",
    label: "Schedule & Assign",
    component: AddWorkOrderScheduleComponent,
    step: 3,
    active: false,
    icon: "./assets/svg/ic-user-schedule.svg",
  },
  {
    id: "preview-and-save",
    label: "Preview & Save",
    component: AddWorkOrderPreviewComponent,
    step: 4,
    active: false,
    icon: "./assets/svg/ic-check-double.svg",
  },
];
