import { Injectable, signal } from "@angular/core";

@Injectable({ providedIn: "root" })
export class NewWorkOrderService {
  readonly currentStep = signal<number>(0);

  setToStep(step: number) {
    this.currentStep.set(step);
  }
}
