import { CdkDragEnd, DragRef } from "@angular/cdk/drag-drop";
import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { iconTM } from "@app/core/const/roam-icon";
import {
  MOCK_DATA_EMPLOYEE,
  ScheduleStore,
} from "../table-schedule/schedule.store";
import { ITask } from "../table-schedule/schedule.interface";
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { ScheduleService } from "../table-schedule/schedule.service";
import { MatDialog } from "@angular/material/dialog";
import { MaintenanceDialogAddScheduleComponent } from "../../maintenance-dialog-add-schedule/maintenance-dialog-add-schedule.component";
import { dialogConfig } from "@app/core/const/dialog.const";

@Component({
  selector: "app-card-slot",
  templateUrl: "./card-slot.component.html",
  styleUrls: ["./card-slot.component.scss"],
})
export class CardSlotComponent implements OnInit, AfterViewInit {
  iconTM = iconTM;

  @ViewChild("dragHandleRight") dragHandleRight!: ElementRef;
  @ViewChild("containerSlot") containerSlot!: ElementRef;
  @Input() dragBoundary: string = ".drag-place";
  @Input() containerTableContent: any;
  @Input() dataTask!: ITask;
  dragPosition = { x: 0, y: 0 };

  scheduleStore = inject(ScheduleStore);
  scheduleService = inject(ScheduleService);
  dialog = inject(MatDialog);

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.dataTask) {
      //setting width for total hour
      let newWidth =
        this.scheduleService.count30MinIntervals(
          this.dataTask.startTime,
          this.dataTask.endTime
        ) * 50;
      this.resizeBoxElement.style.width = `${newWidth}px`;

      //setting position base on hour position
      let indexStart = this.getTimeIndex(
        this.scheduleService.formatTimeTo12Hour(this.dataTask.startTime)
      );
      let indexEmployee = this.scheduleStore
        .stateEmployees()
        .findIndex(employee => employee.id === this.dataTask.employeeId);
      this.dragPosition = { x: indexStart * 100, y: indexEmployee * 200 };
    }
  }

  getTimeIndex(time: string): number {
    return this.scheduleStore.stateTimesHeader().indexOf(time);
  }

  //FOR SNAPING TO GRID
  constrainPosition = (point: { x: number; y: number }, dragRef: DragRef) => {
    const gridSizeX = 100; // Horizontal grid size
    const gridSizeY = 200; // Vertical grid size

    const boundaryRect = this.containerTableContent?.getBoundingClientRect();
    // Calculate the new position relative to the boundary
    const relativeX = point.x - boundaryRect.left;
    const relativeY = point.y - boundaryRect.top;

    // let indexStart = this.getTimeIndex(this.dataTask.startTime);
    // let indexEmployee = +this.dataTask.employeeId - 1;
    // Snapping logic for X coordinate
    const x = Math.round(relativeX / gridSizeX) * gridSizeX + boundaryRect.left;

    // Snapping logic for Y coordinate
    const y = Math.round(relativeY / gridSizeY) * gridSizeY + boundaryRect.top;
    return { x, y };
  };

  dragResize(dragHandle: HTMLElement, $event: any) {
    this.ngZone.runOutsideAngular(() => {
      this.#onResize(dragHandle, this.resizeBoxElement);
    });
  }

  dragResizeEnd($event: any) {
    let totalWidth = +this.resizeBoxElement.style.width.replace("px", "");
    let totalTime = Math.ceil(totalWidth / 50);
    let startTimeIndex = this.getTimeIndex(
      this.scheduleService.formatTimeTo12Hour(this.dataTask.startTime)
    );

    //update data
    this.dataTask.endTime = this.scheduleService.setSpecificTime(
      this.dataTask.endTime,
      this.scheduleStore.stateTimes()[startTimeIndex * 2 + totalTime]
    );
    this.scheduleStore.updateTask(this.dataTask.taskId, this.dataTask);
    this.dialog
      .open(MaintenanceDialogAddScheduleComponent, {
        ...dialogConfig.addDialogPermissions,
        data: {
          cust: MOCK_DATA_EMPLOYEE.filter(
            x => x.id == this.dataTask.employeeId
          )[0],
          time: "",
          task: this.dataTask,
          currDate: new Date(),
        },
      })
      .afterClosed()
      .subscribe(val => {});
  }

  dragPositionEnd(event: CdkDragEnd) {
    const element = event.source.element.nativeElement;
    const container = this.containerTableContent;

    if (!container) {
      console.error("Container not found");
      return;
    }

    const containerRect = container.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    // Posisi scroll dari container
    const scrollX = container.scrollLeft;
    const scrollY = container.scrollTop;

    // Posisi X dan Y dari elemen relatif terhadap container
    const posX = elementRect.left - containerRect.left + scrollX;
    const posY = elementRect.top - containerRect.top + scrollY;

    // Ukuran grid
    // const gridWidth = 100; // Lebar grid horizontal dalam piksel
    const gridWidth = 50; // Lebar grid horizontal dalam piksel per30 menit
    const gridHeight = 200; // Tinggi grid vertikal dalam piksel

    // Hitung posisi grid
    const gridColumn = Math.floor(posX / gridWidth);
    const gridRow = Math.floor(posY / gridHeight);

    //update data
    this.dataTask.endTime = this.scheduleService.setSpecificTime(
      this.dataTask.endTime,
      this.scheduleStore.stateTimes()[
        gridColumn +
          this.scheduleService.count30MinIntervals(
            this.dataTask.startTime,
            this.dataTask.endTime
          )
      ]
    );
    this.dataTask.startTime = this.scheduleService.setSpecificTime(
      this.dataTask.startTime,
      this.scheduleStore.stateTimes()[gridColumn]
    );

    this.dataTask.employeeId = this.scheduleStore.stateEmployees()[gridRow].id;
    console.log(this.dataTask);
    this.scheduleStore.updateTask(this.dataTask.taskId, this.dataTask);
    // this.dialog
    //   .open(MaintenanceDialogAddScheduleComponent, {
    //     ...dialogConfig.addDialogPermissions,
    //     data: {
    //       cust: MOCK_DATA_EMPLOYEE.filter(
    //         x => x.id == this.dataTask.employeeId
    //       )[0],
    //       time: "",
    //       task: this.dataTask,
    //       currDate: new Date(),
    //     },
    //   })
    //   .afterClosed()
    //   .subscribe(val => {});
  }

  #onResize(dragHandle: HTMLElement, target: HTMLElement) {
    // Get the current position of the drag handle
    const dragHandleRect = dragHandle.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();
    let initialWidth =
      this.scheduleService.count30MinIntervals(
        this.dataTask.startTime,
        this.dataTask.endTime
      ) * 50;
    let newWidth = dragHandleRect.right - targetRect.right + initialWidth;
    newWidth = Math.ceil(newWidth / 50) * 50;
    if (newWidth < 50) {
      target.style.width = `50px`;
      dragHandle.style.transform = `translate3d(0px, 0px, 0px)`;
      return;
    }
    // Set the new width to the target element
    target.style.width = `${newWidth}px`;
    dragHandle.style.transform = `translate3d(0px, 0px, 0px)`;
  }

  //for overlay
  @ViewChild("hoverDivTemplate") hoverDivTemplate!: TemplateRef<any>;
  private overlayRef!: OverlayRef;

  showHoverDiv() {
    let totalWidth = +this.resizeBoxElement.style.width.replace("px", "");
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(
        document.querySelector(`.card-slot-${this.dataTask.taskId}`)!
      )
      .withPositions([
        {
          originX: "start",
          originY: "top",
          overlayX: "start",
          overlayY: "bottom",
          offsetY: -20,
          offsetX: totalWidth / 2 - 150,
        },
      ]);

    this.overlayRef = this.overlay.create({ positionStrategy });

    const portal = new TemplatePortal(
      this.hoverDivTemplate,
      this.viewContainerRef
    );
    this.overlayRef.attach(portal);
  }

  hideHoverDiv() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  get dragHandleRightElement(): HTMLElement {
    return this.dragHandleRight?.nativeElement;
  }
  get resizeBoxElement(): HTMLElement {
    return this.containerSlot?.nativeElement;
  }
  constructor(
    private ngZone: NgZone,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private viewContainerRef: ViewContainerRef
  ) {}
}
