import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ShortcutMenuComponent } from "@app/shared/components/shortcut-menu/shortcut-menu";
import { ShortcutMenuCardComponent } from "@app/shared/components/shortcut-menu/shortcut-menu-card";
import { DialogSelectAssociationComponent } from "./dialog-association/dialog-association.component";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ShortcutMenuComponent,
    ShortcutMenuCardComponent,
    DialogSelectAssociationComponent,
  ],
  exports: [
    ShortcutMenuComponent,
    ShortcutMenuCardComponent,
    DialogSelectAssociationComponent,
  ],
})
export class ShortcutMenuModule {}
