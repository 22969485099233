import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

import { ShortCutMenus } from "@app/core/components/utils/const";
import { MatDialog } from "@angular/material/dialog";
import { IShortCut } from "@app/core/components/utils/data";
import { FinancialsDialogComponent } from "@app/pages/financials/dialogs/financials-dialog/financials-dialog.component";
import { DeviceDetectorService } from "ngx-device-detector";
import { LogService } from "@app/shared/services/log.service";
import { CommonModule } from "@angular/common";
import { ShortcutMenuCardComponent } from "@app/shared/components/shortcut-menu/shortcut-menu-card";
import { DialogSelectAssociationComponent } from "../dialog-association/dialog-association.component";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { distinctUntilChanged, Subject, takeUntil } from "rxjs";
import { dialogConfig } from "@app/core/const/dialog.const";
import { MaintenanceDialogNewWorkOrderComponent } from "@app/pages/maintenance/shared/dialog/maintenance-dialog-new-work-order/maintenance-dialog-new-work-order.component";
import { TaskLazyUi } from "@app/pages/task/+data-access";
import { GlobalFormDialog } from "@app/shared/dialogs/global-form/container/global-form-dialog";
import { GlobalFormDialogDataModel } from "@app/shared/dialogs/global-form/model";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ShortcutMenuCardComponent,
    DialogSelectAssociationComponent,
  ],
  selector: "app-shortcut-menu",
  templateUrl: "./shortcut-menu.component.html",
  styleUrls: ["./shortcut-menu.component.scss"],
})
export class ShortcutMenuComponent implements OnInit, OnDestroy {
  @Output() shortcutChange: EventEmitter<void>;

  private destroy$ = new Subject<void>();
  public shortcutMenus: any;

  constructor(
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    private logService: LogService,
    private userConfig: UserConfigStore,
    private ui: TaskLazyUi
  ) {
    this.shortcutChange = new EventEmitter();
  }

  ngOnInit(): void {
    this.shortcutMenus = { ...ShortCutMenus };
  }

  clearActiveShortcut(): void {
    for (const key in this.shortcutMenus) {
      this.shortcutMenus[key].forEach((menus: IShortCut, index: number) => {
        this.shortcutMenus[key][index].focus = false;
      });
    }
  }

  public closeShortcutMenuHandler(): void {
    this.logService.track("close-shortcut-modal");
    this.clearActiveShortcut();
    this.shortcutChange.emit();
  }

  public borderColorChange(data: IShortCut): void {
    for (const key in this.shortcutMenus) {
      this.shortcutMenus[key].forEach((menus: IShortCut, index: number) => {
        this.shortcutMenus[key][index].focus = menus.id === data.id;
      });
    }
    //IF SELECTED ALL THEN NEED TO CHANGE TO CERTAIN ASOCIATION
    let associationId = this.userConfig.signalSelectedAssociationId();
    if (associationId == "all") {
      this.dialog
        .open(DialogSelectAssociationComponent, {
          ...dialogConfig.confirm,
          data: {},
        })
        .afterClosed()
        .subscribe(val => {
          if (val) {
            data.financialKeyDialog ? this.openDialog(data.financialKeyDialog)
            : data.componentDialog ? this.openAddNewDialog(data.componentDialog)
            : data.contactDialog ? this.openDialogContact(data.contactDialog)
            : data.reportDialog ? this.openDialogReport(data.reportDialog)
            : data.id === "work-order" ? this.openWorkOrder()
            : data.id === "task" ? this.openTask()
            : null;

            this.shortcutChange.emit();
          }
        });
    } else {
      data.financialKeyDialog ? this.openDialog(data.financialKeyDialog)
      : data.componentDialog ? this.openAddNewDialog(data.componentDialog)
      : data.contactDialog ? this.openDialogContact(data.contactDialog)
      : data.reportDialog ? this.openDialogReport(data.reportDialog)
      : data.id === "work-order" ? this.openWorkOrder()
      : data.id === "task" ? this.openTask()
      : null;

      this.shortcutChange.emit();
    }
  }

  private openDialog(type: string): void {
    setTimeout(() => {
      this.openFinancialDialog(type);
    }, 500);
  }

  private openWorkOrder() {
    this.dialog.open(MaintenanceDialogNewWorkOrderComponent, {
      ...dialogConfig.full,
    });
  }

  private openTask() {
    this.ui.openTaskEditor({ boardId: "123" });
  }

  private openDialogContact(module: string) {
    console.log(module);
    this.dialog.open(GlobalFormDialog, {
      ...dialogConfig.formEdit,
      data: {
        module: module,
      } as GlobalFormDialogDataModel,
    });
  }

  private openFinancialDialog(_type: string) {
    this.dialog.open(FinancialsDialogComponent, {
      panelClass: "financial-panel",
      maxHeight: "95vh",
      height: "95vh",
      data: {
        type: _type,
        id: "",
      },
    });

    this.clearActiveShortcut();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  private openAddNewDialog(_component: any): void {
    this.dialog.open(_component, {
      panelClass: "no-padding-container",
      width: this.isMobile ? "auto" : "55vw",
      maxWidth: "95vw",
      maxHeight: "100vh",
    });
  }

  private openDialogReport(_component: any): void {
    this.dialog.open(_component, {
      ...dialogConfig.formFill
    });
  }
}
