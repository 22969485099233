import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { MaintenanceDialogAddScheduleComponent } from "../../maintenance-dialog-add-schedule/maintenance-dialog-add-schedule.component";
import { MatDialog } from "@angular/material/dialog";
import { dialogConfig } from "@app/core/const/dialog.const";
import { ScheduleStore } from "./schedule.store";
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";

@Component({
  selector: "app-table-schedule",
  templateUrl: "./table-schedule.component.html",
  styleUrls: ["./table-schedule.component.scss"],
})
export class TableScheduleComponent implements OnInit, AfterViewInit {
  @ViewChild("scheduleBody") private scheduleBody!: ElementRef;

  @Input() taskData: any;

  boundayCdk = ".table-schedule-slots";
  today = new Date();
  isLoading = signal<boolean>(false);
  dialog = inject(MatDialog);
  scheduleStore = inject(ScheduleStore);
  overlay = inject(Overlay);
  overlayPositionBuilder = inject(OverlayPositionBuilder);
  viewContainerRef = inject(ViewContainerRef);
  @ViewChild("hoverDivTemplate") hoverDivTemplate!: TemplateRef<any>;
  overlayRef!: OverlayRef;
  hoverData: string = "";

  getActive(cust: any, time: any) {
    return !cust.workHour.some((v: string) => time.includes(v));
  }

  ngOnInit(): void {
    this.scheduleStore.resetTimeHeaderState();
    this.scheduleStore.resetTimeState();
    //initate get data by date
    this.goToday();
  }
  ngAfterViewInit(): void {
    this.scrollToWorkingTime();
  }

  scrollToWorkingTime() {
    this.scheduleBody.nativeElement.scrollTo({
      left: 800,
      behavior: "smooth",
    });
  }

  goToday() {
    this.today = new Date();
    this.getDataTask(this.today.toDateString());
  }

  prevDay() {
    this.today = new Date(this.today.setDate(this.today.getDate() - 1));
    this.getDataTask(this.today.toDateString());
  }

  nextDay() {
    this.today = new Date(this.today.setDate(this.today.getDate() + 1));
    this.getDataTask(this.today.toDateString());
  }

  getDataTask(data: string) {
    //TODO set up api to get data task by date
  }

  setTask(cust: any, time: any) {
    this.dialog
      .open(MaintenanceDialogAddScheduleComponent, {
        ...dialogConfig.addDialogPermissions,
        data: {
          cust,
          time,
          task: this.taskData,
          currDate: this.today,
        },
      })
      .afterClosed()
      .subscribe(val => {});
  }

  showHoverDiv(event: MouseEvent, time: string) {
    this.hoverData = time;
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo({ x: event.clientX, y: event.clientY })
      .withPositions([
        {
          originX: "center",
          originY: "center",
          overlayX: "center",
          overlayY: "bottom",
          offsetY: -10, // Adjust this value as needed
        },
      ]);

    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    this.overlayRef = this.overlay.create({ positionStrategy });
    const portal = new TemplatePortal(
      this.hoverDivTemplate,
      this.viewContainerRef
    );
    this.overlayRef.attach(portal);
  }

  hideHoverDiv() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
